import Team from "./Team";
import DefaultFooter from "components/DefaultFooter";
import React from "react";
import Iframe from "react-iframe";
//import icon from "../../assets/img/proposito.png";
const Hub = () => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      {/* <section className="py-2 text-comarcaBlack">
        <div className="container mx-auto flex flex-col items-center justify-center max-w-lg p-4 lg:max-w-full sm:p-10 lg:flex-row">
          <div className="flex flex-col items-center justify-center flex-1 p-4 pb-8 text-center rounded-md sm:p-8 lg:p-16  shadow-lg ">
            <p className="font-exo text-justify">
              El impacto que genera una nueva idea de negocio, en las
              transformaciones ocupacionales, en los profesionales y
              organizaciones que se traducen en innovaciones disruptivas para el
              desarrollo de un país integrada con la aplicación de la
              tecnología, son factores que han tomado mucho protagonismo en el
              proceso de re adaptación y resiliencia, en las nuevas maneras de
              hacer economía efecto de la pandemia. El ritmo en que se producen
              y las múltiples influencias recíprocas provocan atención en
              nuestra actividad alienada al ecosistema. Entendiendo esto, y con
              el objetivo de promover una "cultura emprendedora sostenible" con
              el tejido socioeconómico, el Grupo Co Marca diseña 3 ejes
              troncales que apoyan acompañan y amortiguan en todo el Proceso de
              Cultivo de cada idea hasta su despegue, mediante componentes
              interrelacionados que impulsan estrategias y líneas de acción con
              un equipo para que funcionan como agentes dinamizadores de esas
              transformaciones.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center flex-1 p-4 pb-8 sm:p-8 lg:p-16 bg-gray-50">
            <img src={icon} alt="iconProposito" />
          </div>
        </div>
      </section> */}

      <section className="font-exo font-bold">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="bfcc89c7-3b4a-491a-bc7e-53e26502ff69"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#bfcc89c7-3b4a-491a-bc7e-53e26502ff69)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">CON</span>
              </span>{" "}
              PROPÓSITO
            </h2>
            <p className="text-base text-gray-700 md:text-lg text-justify">
              Somos CO MARCA CM Group, con más de 4 años como actor estratégico
              en el ecosistema emprendedor, enfocamos nuestro propósito a la
              contribución integral al sector mediante el desarrollo, apoyo y
              acompañamiento del mismo.
            </p>
          </div>
          <div className="grid gap-8 row-gap-8 lg:grid-cols-3">
            <div className="sm:text-center">
              <div className="flex items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-white bg-comarca sm:mx-auto">
                1
              </div>
              <h6 className="mb-2 font-semibold leading-5 text-xl">Misión</h6>
              <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto text-justify">
                Somos agentes de cambio y contribución efectiva a
                emprendimientos bolivianos de los múltiples sectores en sus
                distintas etapas a través de recursos, metodologías y
                transformación digital bajo componentes efectivos de
                colaboración en pro del desarrollo del país.
              </p>
            </div>
            <div className="sm:text-center">
              <div className="flex items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-white bg-comarcaBlue sm:mx-auto">
                2
              </div>
              <h6 className="mb-2 font-semibold leading-5 text-xl">Visión</h6>
              <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto text-justify">
                Ser el principal ecosistema y red de negocios en Bolivia que
                abarca los distintos sectores, un colectivo de contribución al
                emprendimiento, comprometido en su proceso, sostenibilidad y
                competitividad en el mercado nacional e internacional
              </p>
            </div>
            <div className="sm:text-center">
              <div className="flex items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full text-deep-purple-accent-400 bg-comarcaYellow sm:mx-auto">
                3
              </div>
              <h6 className="mb-2 font-semibold leading-5 text-xl">
                Propuesta única de valor
              </h6>
              <p className="max-w-md mb-3 text-sm text-gray-900 sm:mx-auto text-justify">
                ¡Encontramos las maneras! Creamos sinergias entre cada tipo de
                coworker acercando la brecha entre actores del ecosistema
                emprendedor desde sus distintas posibilidades y competencias,
                contribuyendo a que más emprendedores despeguen sus ideas.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="w-10/12 md:w-7/12 lg:6/12 mx-auto relative py-20">
          <h1 className="text-3xl text-center font-exo font-bold text-comarcaBlack">
            RESULTADOS DEL SISTEMA COWORKING
          </h1>
          <div className="border-l-2 mt-10">
            <div className="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-comarcaOrange text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0 text-comarcaBlack">
              <div className="w-5 h-5 bg-comarcaOrange absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

              <div className="w-10 h-1 bg-comarcaOrange  absolute -left-10 z-0"></div>

              <div className="flex-auto font-exo text-comarcaBlack">
                <h1 className="text-lg">2018</h1>
                <h1 className="text-xl font-bold">ADN Emprendedor</h1>
                <h3 className="text-justify">
                  Encuentro de emprendedores en etapa temprana, enfocado a un
                  relevamiento situacional del ecosistema y creación de
                  comunidad, capacitaciones, conversatorio, grupos focales y
                  dinámicas que muestren la realidad y demandas del sector.{" "}
                </h3>
              </div>
            </div>

            <div className="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-comarca text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
              <div className="w-5 h-5 bg-comarca absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

              <div className="w-10 h-1 bg-comarca absolute -left-10 z-0"></div>

              <div className="flex-auto">
                <h1 className="text-lg font-exo">2019</h1>
                <h1 className="text-xl font-bold font-exo">QUALITY MEETING</h1>
                <h3 className="text-justify">
                  RUEDA DE INVERSIÓN que da oportunidad a emprendimientos tipo
                  startups bolivianos a presentar sus ideas y/o proyectos frente
                  a inversores que están dispuestos a impulsar una idea de
                  negocio disruptiva y con proyección global mediante un
                  elevator pitch.
                </h3>
              </div>
            </div>

            <div className="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-comarcaBlue text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
              <div className="w-5 h-5 bg-comarcaBlue absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

              <div className="w-10 h-1 bg-comarcaBlue absolute -left-10 z-0"></div>

              <div className="flex-auto font-exo">
                <h1 className="text-lg">2020 - 2022</h1>
                <h1 className="text-xl font-bold">Co MARCALAB</h1>
                <h3 className="text-justify">
                  El laboratorio es un proceso de incubación, dedica sus
                  esfuerzos en desarrollar, acompañar e impulsar ideas de
                  negocio en procesos emergentes algunos de ellos incubados
                  desde la concepción de la idea de negocio y otros externos en
                  desarrollo y acompañamiento
                </h3>
              </div>
            </div>

            <div className="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-comarcaPurple text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
              <div className="w-5 h-5 bg-comarcaPurple absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

              <div className="w-10 h-1 bg-comarcaPurple absolute -left-10 z-0"></div>

              <div className="flex-auto font-exo">
                <h1 className="text-lg">Proximamente...</h1>
                <h1 className="text-xl font-bold">COMARCATON</h1>
                <h3 className="text-justify">
                  Formar nuevas STARTUPS mediante un equipo multidisciplinarios
                  de jóvenes profesionales cuyo objetivo es el desarrollo
                  colaborativo para formar un modelo de negocio invertible{" "}
                </h3>
              </div>
            </div>

            <div className="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-comarcaYellow text-comarcaBlack rounded mb-10 flex-col md:flex-row">
              <div className="w-5 h-5 bg-yellow-600 absolute -left-10 transform -translate-x-2/4 rounded-full z-10 -mt-2 md:mt-0"></div>

              <div className="w-10 h-1 bg-comarcaYellow absolute -left-10 z-0"></div>

              <div className="flex-auto font-exo">
                <h1 className="text-lg">Proximamente...</h1>
                <h1 className="text-xl font-bold">SISTEMA ROTATIVO</h1>
                <h3>
                  Articulación de las grandes empresas nacionales que
                  contribuyan directa e indirectamente al nacimiento de nuevos
                  emprendedores afiliados coworkers{" "}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mx-auto p-2 lg:p-4 bg-white font-exo">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="p-4 lg:p-12 rounded-2xl overflow-hidden bg-blue-50">
              <div className="flex items-center text-comarca">
                <p className="text-sm font-bold uppercase">Sección 1</p>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>

              <h2 className="mt-4 text-3xl font-semibold text-slate-800">
                Cowork con propósito
              </h2>

              <p className="mt-4 text-md text-comarcaBlack text-justify text-md font-semibold">
                Ecosistema colaborativo al emprender, enfocamos nuestro
                desarrollo a las necesidades tangibles e intangibles propias del
                inicio de una idea de negocio. El propósito está en sostener,
                amortiguar, apoyar y dar oportunidad a las ideas, crear una
                comunidad de apoyo, buscando sinergias efectivas que disminuyan
                los altos índices de fracaso muy característico del sector
              </p>
              <div className="flex justify-center mt-10">
                <a
                  className="group relative inline-flex items-center overflow-hidden rounded-xl bg-comarca px-8 py-3 text-white focus:outline-none focus:ring active:bg-comarca"
                  href="/services"
                >
                  <span className="text-sm font-medium transition-all group-hover:ml-4">
                    COWORKING
                  </span>
                </a>
              </div>
              <div className="mt-12 flex justify-center items-center hover:scale-125 transform ease-in-out duration-150 transition-transform">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/landing-comarca-storage.appspot.com/o/Services%2FCowork%2FCowork1.png?alt=media&token=a71d69a5-7bb1-4753-99c4-34e700d0e7e4"
                  alt="logo"
                  className="rounded-xl"
                />
              </div>
            </div>

            <div className="p-4 lg:p-12 rounded-2xl overflow-hidden ">
              <div className="flex items-center text-comarcaBlue">
                <p className="text-sm font-bold uppercase">Sección 2</p>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>

              <h2 className="mt-4 text-3xl font-semibold text-slate-800">
                Co Marca Lab
              </h2>

              <p className="mt-4 text-md text-comarcaBlack text-justify font-semibold">
                Creamos un laboratorio de emprendimiento, en fases emergentes de
                un emprendedor, mediante un proceso metodológico de incubación
                de una idea de negocio.
              </p>
              <div className="flex justify-center mt-10">
                <a
                  className="group relative inline-flex items-center overflow-hidden rounded-xl bg-comarcaBlue px-8 py-3 text-white focus:outline-none focus:ring active:bg-comarcaBlue"
                  href="/emprende "
                >
                  <span className="text-sm font-medium transition-all group-hover:ml-4">
                    RESULTADOS
                  </span>
                </a>
              </div>

              <div className="mt-12 flex justify-center items-center hover:scale-125 transform ease-in-out duration-150 transition-transform rounded-xl">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/landing-comarca-storage.appspot.com/o/Services%2FCowork%2FCOMARCALAP.png?alt=media&token=7c03dd7c-42fa-4f94-96a3-9cee41f462dd"
                  alt="logo"
                  className="rounded-xl"
                />
              </div>
            </div>

            <div className="p-4 lg:p-12 rounded-2xl overflow-hidden bg-green-50">
              <div className="flex items-center text-green-500">
                <p className="text-sm font-bold uppercase">Sección 3 </p>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>

              <h2 className="mt-4 text-3xl font-semibold text-slate-800">
                Management and Business Technology Projects
              </h2>

              <p className="mt-4 text-md text-comarcaBlack text-justify font-semibold">
                Gestión, desarrollo y ejecución de proyectos de inversión con
                soluciones tecnológicas, adaptadas a las necesidades
                individuales, corporativas empresariales, respondiendo
                problemas, mediante proyectos multipropósito.
              </p>
              <div className="flex justify-center mt-10">
                <button
                  className="group relative inline-flex items-center overflow-hidden rounded-xl bg-comarca px-8 py-3 text-white focus:outline-none focus:ring active:bg-comarca"
                  onClick={() => setShowModal(true)}
                >
                  <span className="text-sm font-medium transition-all group-hover:ml-4">
                    Ver más
                  </span>
                </button>
                {showModal ? (
                  <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                      <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                          {/*header*/}
                          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                              Proyectos de Gestión y Tecnología{" "}
                            </h3>
                            <button
                              className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                              onClick={() => setShowModal(false)}
                            >
                              <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                ×
                              </span>
                            </button>
                          </div>
                          {/*body*/}
                          <div className="relative p-6 flex-auto">
                            <div className=" justify-center flex flex-col mx-auto  items-center ">
                              <div className="flex flex-col items-center justify-center flex-1 p-4 pb-8">
                                <img
                                  className="w-32 h-32"
                                  src="https://firebasestorage.googleapis.com/v0/b/landing-comarca-storage.appspot.com/o/Products%2Fl6.png?alt=media&token=4e937d8b-22c3-4d1b-b8c3-57e964b15cb7"
                                />
                              </div>
                              <div className="flex flex-col items-center justify-center flex-1 p-4 pb-8">
                                <img
                                  className="w-32 h-32"
                                  src="https://firebasestorage.googleapis.com/v0/b/landing-comarca-storage.appspot.com/o/Products%2Fl2.png?alt=media&token=96b1a9aa-49dd-4c9d-8312-b7abe0064bc4"
                                />
                              </div>
                            </div>
                          </div>
                          {/*footer*/}
                          <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                              className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => setShowModal(false)}
                            >
                              Cerrar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                  </>
                ) : null}
              </div>

              {/* <div className="flex justify-center mt-10">
                <a
                  className="group relative inline-flex items-center overflow-hidden rounded-xl bg-comarcaPurple px-8 py-3 text-white focus:outline-none focus:ring active:bg-comarcaPurple"
                  href="/emprende "
                >
                  <span className="text-sm font-medium transition-all group-hover:ml-4">
                    Ver más
                  </span>
                </a>
              </div> */}

              <div className="mt-12 flex justify-center items-center hover:scale-125 transform ease-in-out duration-150 transition-transform">
                <img
                  src="https://images.pexels.com/photos/1261427/pexels-photo-1261427.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="logo"
                  className="rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Team />
      <DefaultFooter />
    </>
  );
};
export default Hub;
